/**
 * Managing everything regarding the help dialog.
 */
import { HelpSection } from "@/constants/helpSections";
import { defineStore } from "pinia";

export const useHelpDialogStore = defineStore("helpDialog", {
  state: () => ({
    isOpen: false,
    currentSection: HelpSection.Projects
  }),

  actions: {
    async openDialog(startingSection: HelpSection = HelpSection.Projects) {
      this.currentSection = startingSection;
      this.isOpen = true;
    },
    async closeDialog() {
      this.isOpen = false;
      this.currentSection = HelpSection.Projects;
    }
  }
});
