import _Vue from "vue";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';
import duration from 'dayjs/plugin/duration';

dayjs.extend(relativeTime);
dayjs.extend(isBetween)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(weekOfYear);
dayjs.extend(weekday);
dayjs.extend(duration);

export function DayJsPlugin(Vue: typeof _Vue, options?: any): void {
    Vue.prototype.$dayjs = dayjs;
}