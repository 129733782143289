import { HelpCategory } from "@/components/helperDialog/HelpCategory";

const toolbox: HelpCategory = {
  title: "Toolbox",
  sections: [
    {
      title: "Basic Font Styling",
      content: `
      <style>
      .column {
        float: left;
        width: 50%;
      }
      
      /* Clear floats after the columns */
      .row:after {
        content: "";
        display: table;
        clear: both;
      }
      </style>
      <p>
        <div>The user can perform the following actions by highlighting the text and clicking the appropriate icon in the toolbox on the right side of the screen.</div>
      </p>
      <div class="row">
        <div class="column">
        <ol>
          <li>Bold</li>
          <li>Italic</li>
          <li>Underline</li>
          <li>Strikethrough</li>
          <li>Bullet list</li>
          <li>Numbered list</li>
          <li>Table</li>
          <li>Justify Content</li>
          <li>Color</li>
        </ol>
        </div>
        <div class="column">
          <img src="/helpImages/ToolboxBasic.png"></img>
        </div>
      </div>
      `
    },
    {
      title: "Adding Tables",
      content: `
      <ol>
        <li>Click the Create Table icon from the toolbox
          <ul>
            <li>To edit a table, hover the table and click edit</li>
          </ul>
        </li>
        <li>Fill out the table details</li>
        <li>Use the add/remove rows and columns section of the editor to make the table that suits your needs</li>
        <li>Adding a footnote is optional</li>
        <li>Click Save changes to add the table</li>
      </ol>
      <img src="/helpImages/ToolboxAddingTable.png" />
      `
    },
    {
      title: "Adding Charts",
      content: `
      <ol>
        <li>Click the Add chart icon from the toolbox
          <ul>
            <li>To edit a chart, hover the chart and click edit</li>
          </ul>
        </li>
        <li>Fill in the chart details</li>
        <li>Click Save Changes</li>
      </ol>
     
      <div style="margin-top: 5px">
        <h3><strong>Adding Columns</strong></h3>
        <p>To add an additional column, click the options menu beside one of the existing columns</p>
        <img src="/helpImages/ToolboxChartAddColumn.png" />
      </div>

      <div style="margin-top: 5px">
        <h3><strong>Adding Rows</strong></h3>
        <p>To add an additional row, click the options menu beside one of the existing rows</p>
        <img src="/helpImages/ToolboxChartAddRow.png" />
      </div>
      `
    },
    {
      title: "Abbreviations",
      content: `
      <p>Abbreviations that are created will automatically appear in the table of Abbreviations. When viewing a document on the site you will always be presented with the abbreviated form. When a document is exported, each abbreviation will automatically be numbered and contain a link to the table of abbreviations.</p>
      
      <ol>
        <li>Put your cursor in the position you want the abbreviation to appear</li>
        <li>Click the “Abbreviations” icon in the editing toolbar</li>
        <li>Click "+" to create a new abbreviation or select the abbreviation to add/edit</li>
      </ol>

      <img src="/helpImages/ToolboxAbbreviations1.png" />
      <img src="/helpImages/ToolboxAbbreviations2.png" />
      `
    },
    {
      title: "Variables",
      content: `
      <p>Variables can be added to a document. These variables allow you to create content that is repeated throughout the document then when you update the variable, the content is updated in all parts of the document.</p>
      
      <img src="/helpImages/ToolboxVariables1.png" />
      <img src="/helpImages/ToolboxVariables2.png" />
      `
    },
    {
      title: "Hyperlinks",
      content: `
      <p>You can add "links" to another part of the document by:</p>
      <li>Clicking "Add Link" in the toolbox</li>
      <li>Select the type of link you want:
        <ul>
          <li>Section Link: Add a link to a specific section/subsection of the document</li>
          <li>Figure Link: Add a link to a specific chart in the document</li>
          <li>Table Link: Add a link to a specific table in the document</li>
        </ul>
      </li>
      <li>Enter the description of the link
        <ul>
          <li>This is the text that will appear as the hyperlink</li>
        </ul>
      </li>
      <li>Click "Add to document"</li>
      

      <img src="/helpImages/ToolboxHyperlink.png" />
      `
    }
  ]
};

export default toolbox;
