import Vue from "vue";
import App from "@/App.vue";
import VueRouter from "vue-router";
import router from "@/router";
import vuetify from "@/plugins/vuetify";
import { DayJsPlugin } from "@/plugins/dayjs";
// @ts-ignore
import { VueMasonryPlugin } from "vue-masonry";

import VueCompositionApi from "@vue/composition-api";

Vue.config.productionTip = false;

Vue.use(VueCompositionApi);

import { createPinia, PiniaVuePlugin } from "pinia";

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.use(VueRouter);
Vue.use(DayJsPlugin);
Vue.use(VueMasonryPlugin);

new Vue({
  pinia,
  router,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
