import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { useUserStore } from "@/stores/userStore";
import { Roles } from "./api/OtiumAppApi";

enum RoutePermissions {
  accessToTemplates,
  admin
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    redirect: { name: "Login" },
    meta: {
      noAuthRequired: true
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: {
      noAuthRequired: true
    }
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/Register.vue"),
    meta: {
      noAuthRequired: true
    }
  },
  {
    path: "/resetPassword",
    name: "ResetForgotPassword",
    component: () => import("@/views/ResetPassword.vue"),
    props: true,
    meta: {
      noAuthRequired: true
    }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/components/dashboard/Dashboard.vue")
  },
  {
    path: "/project/:projectId",
    name: "Project",
    component: () => import("@/components/projects/Project.vue"),
    props: true
  },
  {
    path: "/templates",
    name: "Templates",
    component: () => import("@/components/Templates/Templates.vue"),
    props: true,
    meta: {
      routePermissions: [RoutePermissions.accessToTemplates]
    }
  },
  {
    path: "/paywall",
    name: "Paywall",
    component: () => import("@/views/Paywall.vue"),
    props: true,
    meta: {
      noAuthRequired: true
    }
  },
  {
    path: "/template/:templateId",
    name: "Template",
    component: () => import("@/components/Templates/TemplateEditor.vue"),
    props: true,
    meta: {
      routePermissions: [RoutePermissions.accessToTemplates]
    }
  },
  {
    path: "/resetPassword",
    name: "ResetForgotPassword",
    component: () => import("@/components/authentication/ResetPasswordForm.vue"),
    props: true,
    meta: {
      noAuthRequired: true
    }
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("@/components/account/AccountManagement.vue"),
    props: true
  },
  {
    path: "/organization",
    name: "Organization",
    component: () => import("@/components/account/Organization.vue"),
    props: true
  },
  {
    path: "/acceptInvite",
    name: "AcceptInvite",
    component: () => import("@/components/authentication/AcceptInvite.vue"),
    props: true,
    meta: {
      noAuthRequired: true
    }
  },
  {
    path: "/templates/archived",
    name: "ArchivedTemplates",
    component: () => import("@/components/Templates/ArchivedTemplates.vue"),
    props: true
  },
  {
    path: "/projects/archived",
    name: "ArchivedProjects",
    component: () => import("@/components/dashboard/ArchivedDashboard.vue"),
    props: true
  },
  {
    path: "/admin/users",
    name: "AdminUsers",
    component: () => import("@/components/admin/AdminUsers.vue"),
    props: true,
    meta: {
      RoutePermissions: [RoutePermissions.admin]
    }
  },
  {
    path: "/noOrganizations",
    name: "NoOrganizations",
    component: () => import("@/components/account/NoOrganizations.vue"),
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach(async (to, _, next) => {
  const store = useUserStore();
  if (to.meta && to.meta.noAuthRequired) {
    next();
    return;
  } else {
    // If there are route permissions check to ensure the user can access the route.
    if (to.meta && to.meta.routePermissions) {
      const permissions = to.meta.routePermissions;
      var valid = true;

      // Ensure the user has access to templates
      if (permissions.includes(RoutePermissions.accessToTemplates) && !store.hasAccessToTemplates) {
        valid = false;
      }

      // Ensure the user is an admin
      if (permissions.includes(RoutePermissions.admin) && !store.isAdmin) {
        valid = false;
      }

      if (valid) {
        next();
      } else {
        next({ name: "Dashboard" });
      }
    }
    if (store.isLoggedIn) {
      await store.checkTokenExpiringSoon();
      next();
    } else {
      next({ name: "Login" });
    }
  }
});

export default router;
