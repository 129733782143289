/**
 * Managing everything regarding errors.
 * Any error that is added to this store will be displayed as a snackbar in the app.
 */
import { defineStore } from "pinia";

export const useErrorStore = defineStore("error", {
  state: () => ({
    error: null as string | null,
    timeoutId: -1
  }),

  actions: {
    /**
     * Add a new error. This will display the error as a snackbar in the app for 4 seconds.
     */
    async addError(error: string) {
      clearTimeout(this.timeoutId);
      this.error = error;
      this.timeoutId = setTimeout(() => { this.error = null }, 4000);
    },
  }
});
