import { HelpCategory } from "@/components/helperDialog/HelpCategory";

const projects: HelpCategory = {
  title: "Projects",
  sections: [
    {
      title: "Creating a project",
      content: `
      <ol>
        <li>Navigate to the project dashboard</li>
        <li>Click Create Project</li>
        <li>Select an organization to create the project for</li>
        <li>Enter a project name</li>
        <li>Click Create Project</li>
      </ol>
      <img src="/helpImages/CreatingProject.png"></img>
      `
    },
    {
      title: "Archiving a project",
      content: `
      <ol>
        <li>Select the project to archive from the project dashboard/li>
        <li>Click the options menu</li>
        <li>Click Archive</li>
      </ol>
      <img src="/helpImages/ArchiveProject.png"></img>
      `
    },
    {
      title: "Restoring an archived project",
      content: `
      <ol>
        <li>Click Archived Projects from the project dashboard</li>
      </ol>
      <img src="/helpImages/RestoreArchivedProjectStep1.png"></img>
      <ol start="2">
        <li>Click Unarchive on the project card/li>
      </ol>
      <img src="/helpImages/RestoreArchivedProjectStep2.png"></img>
      `
    },
    {
      title: "Adding users to a project",
      content: `
      <ol>
        <li>Click edit on the project from the project dashboard page/li>
      </ol>
      <img src="/helpImages/AddUsersToProjectStep1.png"></img>
      <ol start="2">
        <li>Select the reviewers to add and click Add User
          <ul>
            <li>Note: You will only see users here that have been added to the organization. </li>
          </ul>
        </li>
      </ol>
      <img src="/helpImages/AddUsersToProjectStep2.png"></img>
      <ol start="3">
        <li>Set the role & access for the reviewer</li>
        <li>Click Save Changes</li>
      </ol>
      <img src="/helpImages/AddUsersToProjectStep3.png"></img>
      `
    }
  ]
};

export default projects;
