import { defineStore } from "pinia";
import {
  AccountsClient,
  ChangePasswordModel,
  IChangePasswordModel,
  ILogoutModel,
  IRefreshTokenModel,
  IUpdateProfileModel,
  LoginModel,
  LogoutModel,
  OrganizationPermissionType,
  ProfileModel,
  ProjectRole,
  RefreshTokenModel,
  Roles,
  UpdateProfileModel
} from "@/api/OtiumAppApi";
import dayjs from "dayjs";
import { userProfileHub } from "@/hubs/userProfileHub";

export enum LoginStatus {
  Success,
  GenericError,
  IncorrectUsernameOrPassword,
  UnverifiedEmail
}

export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    storeAccessToken: null as string | null,
    storeAccessTokenExpiry: null as string | null,
    storeRefreshToken: null as string | null,
    storeUserProfile: null as ProfileModel | null,
    isHubConnected: false
  }),

  getters: {
    accessToken: (state) => state.storeAccessToken,
    accessTokenExpiry: (state) => state.storeAccessTokenExpiry,
    refreshToken: (state) => state.storeRefreshToken,
    isLoggedIn: (state) =>
      state.storeAccessToken != null &&
      state.storeAccessTokenExpiry != null &&
      state.storeRefreshToken != null,
    userProfile: (state) => state.storeUserProfile,
    userProfileOrganizations: (state) => state.storeUserProfile?.organizations,
    userRoles: (state) => state.storeUserProfile?.roles,
    isAdmin: (state) => state.storeUserProfile?.roles.includes(Roles.OtiumAdmin),
    isOrganizationAdmin: (state) => state.storeUserProfile?.adminOrganizationId != undefined,
    hasAccessToTemplates: (state) => {
      const profile = state.storeUserProfile;

      if (profile == null) return false;
      if (profile.roles.includes(Roles.OtiumAdmin)) return true;
      if (profile.roles.includes(Roles.OtiumTemplateCreator)) return true;

      const organizationsWithCreatePermissions = profile.organizations.filter(
        (org) =>
          org.organizationPermissions.includes(OrganizationPermissionType.CanCreateTemplate) &&
          org.organizationCanCreateTemplates
      );
      if (organizationsWithCreatePermissions.length >= 1) return true;

      return false;
    },
    canCreateProjects: (state) => {
      const profile = state.storeUserProfile;

      if (profile == null) return false;

      const organizationsWithCreatePermissions = profile.organizations.filter((org) =>
        org.organizationPermissions.includes(OrganizationPermissionType.CanCreateProject)
      );
      if (organizationsWithCreatePermissions.length >= 1) return true;

      return false;
    },
    hasOrganization: (state) => {
      if (state.storeUserProfile == null) return false;
      return state.storeUserProfile.organizations.length > 0;
    }
  },

  actions: {
    async checkToken() {
      // checks whether the JWT is valid and refreshes it if it's not
      // this relies on the 401 error handler in the axiosClient transformResult refreshing the token and retrying the request
      const accountsClient = new AccountsClient();
      await accountsClient.checkToken();
    },
    /**
     * Checks if the JWT is expiring soon (next 5 minutes)
     * Refreshes it if it is expiring soon
     * It is possible to parse this information from the JWT,
     * but if the server and client time are too different, it won't work.
     */
    async checkTokenExpiringSoon() {
      if (
        this.storeAccessTokenExpiry &&
        dayjs.utc(this.storeAccessTokenExpiry).subtract(5, "minute").isBefore(dayjs.utc())
      ) {
        await this.refreshLogin();
      }
    },
    async login(loginModel: LoginModel): Promise<LoginStatus> {
      const accountsClient = new AccountsClient();
      let result = null;
      try {
        result = await accountsClient.login(loginModel);
      } catch (e: any) {
        switch (e.status) {
          case 401:
            return LoginStatus.IncorrectUsernameOrPassword;
          case 403:
            return LoginStatus.UnverifiedEmail;
        }
        return LoginStatus.GenericError;
      }
      this.storeAccessToken = result.token;
      this.storeAccessTokenExpiry = dayjs.utc().add(result.expiry, "minute").toISOString();
      this.storeRefreshToken = result.refreshToken;

      // load user profile
      await this.getUserProfile();

      if (this.userProfile?.accountId) {
        await userProfileHub.start();
        await userProfileHub.joinAccount(this.userProfile?.accountId);
      }

      return LoginStatus.Success;
    },

    async getUserProfile(): Promise<void> {
      const accountsClient = new AccountsClient();
      this.storeUserProfile = await accountsClient.getUserProfile();
    },

    isWriter(projectId: string) {
      const profile = this.storeUserProfile;
      if (profile == null) return false;

      const organizationsWithWriterPermissions = profile.organizations.find((org) =>
        org.projects.find(
          (p) =>
            p.projectId == projectId && (p.role == ProjectRole.Admin || p.role == ProjectRole.Writer)
        )
      );

      if (organizationsWithWriterPermissions) return true;
      return false;
    },

    receiveNewProfile(firstName: string, lastName: string) {
      if (this.userProfile) {
        this.userProfile.firstName = firstName;
        this.userProfile.lastName = lastName;
      }
    },

    async refreshLogin(): Promise<boolean> {
      if (this.storeRefreshToken == null) return false;
      const accountsClient = new AccountsClient();
      const refreshTokenModel: IRefreshTokenModel = {
        refreshToken: this.storeRefreshToken
      };
      const tokenResponse = await accountsClient.refreshToken(new RefreshTokenModel(refreshTokenModel));
      if (tokenResponse != null) {
        this.storeAccessToken = tokenResponse.token;
        this.storeAccessTokenExpiry = dayjs.utc().add(tokenResponse.expiry, "minute").toISOString();
        this.storeRefreshToken = tokenResponse.refreshToken;
        return true;
      } else {
        this.unsetUser();
        return false;
      }
    },
    async logout() {
      if (this.refreshToken != null) {
        const accountsClient = new AccountsClient();
        const logoutModel: ILogoutModel = {
          refreshToken: this.storeRefreshToken as string
        };
        await userProfileHub.stop();
        await accountsClient.logout(new LogoutModel(logoutModel));
      }
      this.unsetUser();
    },
    unsetUser() {
      this.storeAccessToken = null;
      this.storeAccessTokenExpiry = null;
      this.storeRefreshToken = null;
      this.storeUserProfile = null;
    },

    async updateUserProfile(profile: ProfileModel) {
      const accountsClient = new AccountsClient();
      const model: IUpdateProfileModel = {
        accountId: profile.accountId,
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email
      };
      await accountsClient.updateProfile(new UpdateProfileModel(model));
    },

    async changePassword(currentPassword: string, newPassword: string) {
      const accountsClient = new AccountsClient();
      const model: IChangePasswordModel = {
        currentPassword,
        newPassword
      };
      await accountsClient.changePassword(new ChangePasswordModel(model));
    },

    async verifyPassword(currentPassword: string): Promise<boolean> {
      const accountsClient = new AccountsClient();
      return await accountsClient.verifyPassword(currentPassword);
    }
  }
});
