import { useErrorStore } from './../stores/errorStore';
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { useUserStore } from "@/stores/userStore";
import router from "@/router";

export default abstract class AxiosClient {
  protected transformOptions(options: any) {
    // If there is an access token in the store, attach it to the request
    const userStore = useUserStore();
    if (userStore.accessToken) {
      options.headers["Authorization"] = `Bearer ${userStore.accessToken}`;
    }
    return Promise.resolve(options);
  }

  protected async transformResult(
    url: string,
    response: AxiosResponse,
    processor: (response: AxiosResponse) => any
  ) {
    const path = (new URL(response.config.url!)).pathname;

    switch (response.status) {
      case 401: {
        // do not handle 401 responses for these paths
        if (["/api/users/login"].includes(path.toLowerCase())) {
          return processor(response);
        }
        const userStore = useUserStore();
        const refreshResult = await userStore.refreshLogin();
        if (refreshResult) {
          response.config.headers!["Authorization"] = `Bearer ${userStore.accessToken}`;
          const instance = axios.create();
          return processor(await instance.request(response.config));
        } else {
          this.logoutAndRedirect();
        }
        break;
      }
      case 403: {
        // do not handle 403 responses for these paths
        if (["/api/users/login"].includes(path.toLowerCase())) {
          return processor(response);
        }
        router.push({ name: "Dashboard" });
        const errorStore = useErrorStore();
        errorStore.addError("Access to that resource is forbidden.");
        break;
      }
      case 404: {
        router.push({ name: "Dashboard" });
        const errorStore = useErrorStore();
        errorStore.addError("The requested resource was not found.");
        break;
      }
      case 500: {
        const errorStore = useErrorStore();
        errorStore.addError("There was an error on the server and the request could not be completed.");
        break;
      }
      default:
        return processor(response);
    }
  }

  public getBaseUrl(val?: string): string {
    return process.env.VUE_APP_API_BASE_URL!;
  }

  protected async logoutAndRedirect() {
    const userStore = useUserStore();
    await userStore.logout();
    router.push({ name: "Home" });
  }
}
