















import { defineComponent } from "@vue/composition-api";
import { useErrorStore } from "@/stores/errorStore";
import helperDialog from "@/components/helperDialog/HelperDialog.vue";

export default defineComponent({
  name: "Layout",
  components: {
    helperDialog
  },
  setup() {
    const errorStore = useErrorStore();

    return {
      errorStore
    };
  }
});
