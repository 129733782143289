import { HelpCategory } from "@/components/helperDialog/HelpCategory";

const documents: HelpCategory = {
  title: "Documents",
  sections: [
    {
      title: "Creating a document",
      content: `
      <ol>
        <li>Select a project</li>
        <li>Click the "+" icon to add a new document</li>
        <li>Fill out the form from top to bottom
          <ul>
            <li>Note: The list of templates in the final input will vary based on the options selected in questions 1-4.</li>
          </ul>
        </li>
        <li>Click Add Document to create the document</li>
      </ol>
      <img src="/helpImages/CreatingTemplate.png"></img>
      `
    },
    {
      title: "Setting document to in review",
      content: `
        <ol>
          <li>Click the options menu next to the document version name</li>
          <li>Click Set to in Review</li>
        </ol>
        <img src="/helpImages/DocumentSetToReview.png"></img>
        `
    },
    {
      title: "Adding a review",
      content: `
        <ol>
          <li>When a document is in the In Review state, click Add Review on a section to start the review proccess </li>
          
        </ol>
        <img src="/helpImages/DocumentAddingReview.png"></img>
        <ol start="2">
          <li>Edit the content of the section</li>
          <li>Click Add Review</li>
        </ol>
        `
    },
    {
      title: "Resolving reviews",
      content: `
      <p>
        When viewing a document in the “In Review” state, the writer will see a badge on the “View Reviews” button showing how many reviews are pending on a section. To resolve the review, the writer must: </p>
        <ol>
          <li>Click View Reviews on the section</li>
          
        </ol>
        <img src="/helpImages/DocumentViewReviews.png"></img>
        <ol start="2">
          <li>Select a review to compare to the "Compare To" dropdown</li>
        </ol>
        <img src="/helpImages/DocumentReviewCompareTo.png"></img>
        <ol start="3">
          <li>Click "Reject" if you want to reject the entire review otherwise click "Edit before accepting" to edit the review before it gets finalized into the document.</li>
          <li>After clicking "Edit before accepting", you will have the ability to edit the content of the section. Clicking "Submit with changes", will override the current version with the reviewed version</li>
          </li>
        </ol>
        <img src="/helpImages/DocumentFinishReview.png"></img>
        
        `
    }
  ]
};

export default documents;
