import { HelpCategory } from "@/components/helperDialog/HelpCategory";

const templates: HelpCategory = {
  title: "Templates",
  sections: [
    {
      title: "Creating a template",
      content: `
      <ol>
        <li>Select template on the navigation menu</li>
        <li>Click Create Template</li>
        <li>Fill in the details of the templates
          <ul>
            <li>Organization: If you are part of multiple organizations you will need to select what organization has access to this template.</li>
            <li>Template Name</li>
            <li>Template Summary: Provide a brief description of the template.</li>
          </ul>
        </li>
      </ol>
        <img src="/helpImages/CreatingTemplate.png"></img>
        <ol start="4">
        <li>Click Create Template</li>
      </ol>
      `
    }
  ]
};

export default templates;
