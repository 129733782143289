import _Vue from "vue";
import * as signalR from "@microsoft/signalr";
import { useUserStore } from "@/stores/userStore";

const connection = new signalR.HubConnectionBuilder()
  .configureLogging(signalR.LogLevel.Information)
  .withUrl(`${process.env.VUE_APP_API_BASE_URL}/userProfileHub`, {
    accessTokenFactory: () => {
      const userStore = useUserStore();
      return userStore.accessToken ?? "";
    }
  })
  .withAutomaticReconnect({
    nextRetryDelayInMilliseconds: (retryContext) => {
      const reconnectDelay = Math.random() * 5000 + 2000;
      return reconnectDelay;
    }
  })
  .build();

connection.onreconnecting(function () {
  const userStore = useUserStore();
  userStore.isHubConnected = false;
});

connection.onreconnected(function () {
  const userStore = useUserStore();
  userStore.isHubConnected = true;

  // SignalR groups are lost on disconnect.
  // Rejoin.
  const accountId = userStore.userProfile?.accountId;

  if (accountId !== undefined) {
    userProfileHub.joinAccount(accountId);
  }
});

/**
 * User has updated their name.
 */
connection.on("UserProfileUpdated", (accountId: string, firstName: string, lastName: string) => {
  const userStore = useUserStore();
  userStore.receiveNewProfile(firstName, lastName);
});

export interface UserProfileHub {
  connection: signalR.HubConnection;
  start(): Promise<void>;
  stop(): Promise<void>;
  joinAccount(accountId: string): Promise<void>;
}

export const userProfileHub = {
  connection: connection,

  async start() {
    try {
      const userStore = useUserStore();
      if (userStore.isHubConnected) return;

      await connection.start();

      userStore.isHubConnected = true;
    } catch (err) {
      console.error(err);
      setTimeout(this.start, 5000);
    }
  },

  async stop() {
    const userStore = useUserStore();
    await connection.stop();
    userStore.isHubConnected = false;
  },

  async joinAccount(accountId: string) {
    await connection.send("ConnectToUserProfile", accountId);
  }
} as UserProfileHub;

export function UserProfileHub(Vue: typeof _Vue, options?: any): void {
  Vue.prototype.$userProfileHub = userProfileHub;
}
