import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                primary: '#0388A6',
                secondary: '#424451',
                lightblue: '#95c6d3',
                mediumblue: '#2796b2',
                grey: 'f4f4f4',
            },
        },
    },
});
