






















































































import { computed, defineComponent } from "@vue/composition-api";

import { HelpSection } from "@/constants/helpSections";
import { useHelpDialogStore } from "@/stores/helpDialogStore";
import { HelpCategory } from "@/components/helperDialog/HelpCategory";
import Templates from "@/components/helperDialog/definitions/Templates";
import Projects from "@/components/helperDialog/definitions/Projects";
import Documents from "@/components/helperDialog/definitions/Documents";
import Toolbox from "@/components/helperDialog/definitions/Toolbox";
import { useUserStore } from "@/stores/userStore";

export default defineComponent({
  name: "HelperDialog",
  components: {},
  props: {},
  setup() {
    const helpDialogStore = useHelpDialogStore();
    const userStore = useUserStore();

    const helpComponent = computed((): HelpCategory => {
      switch (helpDialogStore.currentSection) {
        case HelpSection.Projects:
          return Projects;
        case HelpSection.Templates:
          return Templates;
        case HelpSection.Documents:
          return Documents;
        case HelpSection.Toolbox:
          return Toolbox;
        case HelpSection.Menu:
          return {
            title: "",
            sections: []
          };
      }
    });

    function setHelpSection(category: HelpSection) {
      helpDialogStore.currentSection = category;
    }

    const hasAccessToTemplates = computed(() => {
      return userStore.hasAccessToTemplates;
    });

    return {
      helpDialogStore,
      helpComponent,
      HelpSection,
      setHelpSection,
      hasAccessToTemplates
    };
  }
});
